export const returnCookieValue = (key) => {
    // var temp =document.cookie.split(';').map(value => {
    //     return value.trim().split('=')
    // })

    for (let keyvalue of document.cookie.split(';')){
        let [tkey, tvalue] = keyvalue.trim().split('=')
        if (key===tkey){
            return tvalue
        }
    }

    return null
}