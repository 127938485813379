import React from 'react';
import {Container} from '@material-ui/core'
import Card from "../Card/Card.jsx";
import CardBody from "../Card/CardBody.jsx";
import './Error.css';
import ErrorMessage from '../Error/ErrorMessage'

class Error extends React.Component {

  render() {
    return (
        <Container className="error_page" style={{ fontFamily: "Lato" }}>
            <Card style={{height:'70vh', padding:"1rem 0 1rem 0"}}>
                <CardBody>
                  <div className="message">{this.props.message?this.props.message:<ErrorMessage/>}</div>
                </CardBody>
            </Card>
        </Container>
    )

  }
}


export default Error;