import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Card, Button, Paper, TextField, ButtonBase, Snackbar, IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import {Link} from 'react-router-dom';
import apigClientFactory from './chalice-javascript-sdk/apigClient';
// import loginlist from './loginList.png';
import background from './loginBackground blue.png';
import { isMobile } from "react-device-detect";
import CloseIcon from '@material-ui/icons/Close';

import LoginFooter from './components/Footer/LoginFooter';
import OMNIInfo from './components/Global/OMNIInfo';
import * as config from './Config/config';
import {checkCookie} from './utility/checkCookie';
import {secureStorage_getItem} from './utility/secureStorage';

import {dropCookie} from './utility/dropCookie';


const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
});


class ConfirmUser extends React.Component {
    state = {
        confirmcode: '',
        open: checkCookie('vlomni-cookie', 'true')?false:true,
        open1: false,
        errmsg: '',
        password: '',
        username: '',
    };

    endPoints = config.createConfig().isProd ? config.prodEndPoints : config.devEndPoints

    componentDidMount() {
        if ('email' in this.props.location){
            this.setState({username: this.props.location.email?this.props.location.email:''});
        }

    }
        

    confirmUser() {
        const { username, confirmcode } = this.state;

        try{
            //var apigClient = apigClientFactory.newClient();
            var apigClient = apigClientFactory.newClient(undefined, this.endPoints[this.props.location.region].apiurl);
            
            var body = {
                username: username,
                ConfirmationCode: confirmcode,
            }

            // this.props.history.push({
            //     pathname: '/login',
            //     email: this.state.username,
            //     from: this.props.location
            // })             

            apigClient.vlauthConfirmUserPut('', body, '').then(result => {
                //verifies users confirmation code then sends them to login screen

                this.setState({ open1: true });
                this.setState({ errmsg: "Successfully confirmed!" });
                
                // window.location = "/login";
                //will redirect to login page
                this.props.history.push({
                    pathname: '/login',
                    email: this.state.username,
                    from: this.props.location
                })     


            }).catch(error => {
                // console.log('Failed! Details: ' + JSON.stringify(error));
                this.setState({ open1: true });
                this.setState({ errmsg: error.response.data.Error?error.response.data.Error:"Wrong confirmation code, please make sure you have the correct confirmation code!" });
            });

            }catch(error) {
            this.setState({ open1: true });
            this.setState({ errmsg: "This session has expired! Please create an account again."});
        }

    }

    resendConfirmCode = (event) => {
        try {
            const { username } = this.state;
        
            //var apigClient = apigClientFactory.newClient();
            var apigClient = apigClientFactory.newClient(undefined, this.endPoints[this.props.location.region].apiurl);

            var body = {
                username: username,
            }
            
            apigClient.vlauthResendConfirmationCodePut('', body, '').then(result => {
                //resends confirmation code to email entered
                this.setState({ open1: true });
                this.setState({ errmsg: "Another Confirmation code has been sent to your email!" });

            }).catch(error => {
                this.setState({ open1: true });
                this.setState({ errmsg: error.response.data.Error?error.response.data.Error:"Couldn't send the code! Please check to make sure your email is entered correctly!" });
            });
        }catch (error) {
            this.setState({ open1: true });
            this.setState({ errmsg: "This session has expired! Please create an account again."});
        }
    }

    signIn() {
        try {
            const { username, password } = this.state;

            //var apigClient = apigClientFactory.newClient();
            var apigClient = apigClientFactory.newClient(undefined, this.endPoints[this.props.location.region].apiurl);

            var params = {
                username: username,
                password: password,
            }


            apigClient.vlauthSigninUsernamePasswordPut(params, '', '').then(result => {
                //alert('Login Result: ' + JSON.stringify(result.data));
                localStorage.setItem("accesstoken", result.data.IdToken);

                localStorage.setItem("updatetoken", result.data.AccessToken);
                localStorage.setItem("username", username);
                localStorage.setItem("customername", result.data.customername);

                //window.location = "/"
                var paramsTmp = {
                    tablename: "WebServiceCustomer",
                    customername: secureStorage_getItem("customername"),
                }
                var additionalParamsTmp = {
                    queryParams: {
                        fields: "StoreAdminURL"
                    },
                    headers: {
                        "Authorization": localStorage.getItem("accesstoken")
                    }
                }
                apigClient.dbopsGetLatestRecordsTablenameCustomernameGet(paramsTmp, '', additionalParamsTmp).then(result => {
                    //alert('Result: ' + JSON.stringify(result.data));
                    if (result.data.length > 0) {
                        if (result.data[0].StoreAdminURL === null || result.data[0].StoreAdminURL === undefined) {
                            localStorage.setItem("storeadminurl", "null");
                        } else {
                            localStorage.setItem("storeadminurl", result.data[0].StoreAdminURL);
                        }

                        //window.location = "/";
                    }
                    //for gorgias this will be link to page with blurred out background and just the one gorgias tab from admin page
                    window.location = "/gorgiasUser";
                }).catch(error => {
                    // console.log('Failed! Details: ' + JSON.stringify(error));
                    
                });


            }).catch(error => {
                // console.log('Failed! Details: ' + JSON.stringify(error));
                this.setState({ open1: true });
                this.setState({ errmsg: "Wrong username or password!" });
                //alert('Wrong username or password!')
            });
        }catch (error) {
            this.setState({ open1: true });
            this.setState({ errmsg: "This session has expired! Please create an account again."});
        }

    }

    handleUsernameChange = (event) => {
        this.setState({ username: event.target.value });
    }

    handleCodeChange = (event) => {
        this.setState({ confirmcode: event.target.value });
    }

    handlePasswordChange = (event) => {
        this.setState({ password: event.target.value });
    }

    handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            this.confirmUser();
        }
    };

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        dropCookie('vlomni-cookie','true', 2592000)

        this.setState({ open: false });
    };

    handleClickA = () => {
        this.setState({ openA: true });
    };

    handleCloseA = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        this.setState({ openA: false });
    };

    handleClickOpen = () => {
        this.setState({ open1: true });
    };

    handleCloseOpen = () => {
        this.setState({ open1: false });
    };

    render() {
        const { errmsg, username, confirmcode } = this.state;
        document.title = "VL OMNI Confirm Email";

        var form = (
            <React.Fragment>
                <h2 style={{ borderRadius: 5, color: "black", margin: "auto", marginBottom: -25, textAlign: "center", fontSize: 1.8 + "em", fontWeight: 400 }}>VL OMNI - Confirm Login</h2>
                <form>
                    <TextField
                        id="email"
                        label="Email"
                        value={username}
                        onChange={this.handleUsernameChange}
                        onKeyPress={this.handleKeyPress}
                        style={{ marginTop: 5 + 'vh', width: 100 + "%", marginLeft: 'auto', marginRight: 'auto', float: 'left', fontSize: 0.4 + 'em' }}
                    />
                    <TextField
                        id="confirmcode"
                        label="Confirmation Code"
                        value={confirmcode}
                        onChange={this.handleCodeChange}
                        onKeyPress={this.handleKeyPress}
                        style={{ marginTop: 0.5 + 'vh', width: 100 + "%", marginLeft: 'auto', marginRight: 'auto', float: 'left' }}
                    />

                    <Button onClick={this.confirmUser.bind(this)} variant="contained" style={{ backgroundColor: "#00a9e7", color: "white", margin: 'auto', width: 100 + "%", fontSize: 20, fontFamily: "Lato", boxShadow: 'none', marginTop: 1.5 + 'vh' }}>
                        Confirm 
                    </Button>
                </form>
                {/* <div style={{ textAlign: "center", marginTop: 3 + "%", fontSize: 13, marginBottom: '-10px' }}><a href="/recoverPassword" style={{ textDecoration: "none" }}>Forgot your password?</a></div> */}
                <div style={{ textAlign: "center", marginTop: 3 + "%", fontSize: 13 }}>
                    <div>Confirmation code not working? <span  style={{ color: "#00a9e7", textDecoration: "underline", cursor:'pointer' }} onClick={this.resendConfirmCode} color="primary"> Click here </span> </div>
                    <div>Having more trouble? Click here to sign up <Link to="/newUser" style={{ color: "#00a9e7", textDecoration: "underline" }}>Click here</Link> or contact support@virtuallogistics.ca</div>
                </div>
            </React.Fragment>
        )

        var popup = (
            <React.Fragment>
                <Card style={{ position: "absolute", width: 100 + "%", height: 100 + "%", top: 0 + "%", backgroundColor: "rgb(17, 20, 27)" }}>
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        open={this.state.open}
                        autoHideDuration={999999}
                        onClose={this.handleClose.bind(this)}
                        ContentProps={{
                            'aria-describedby': 'message-id',
                        }}
                        message={<span id="message-id">The Site uses cookies. By using this website, you agree to our use of cookies.</span>}
                        action={[
                            <ButtonBase key='close' onClick={this.handleClose.bind(this)} style={{ color: "black", backgroundColor: "#ffd42d", padding: 5, borderRadius: 5 }}>
                                I Understand
                            </ButtonBase>,
                        ]}
                    />
                    <Snackbar style={{ marginTop: "50%" }}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        open={this.state.openA}
                        onClose={this.handleCloseA}
                        ContentProps={{
                            'aria-describedby': 'message-id',
                        }}
                        message={<span id="message-id">Please make sure your username is entered. An email has been sent to your account with a verification code.</span>}
                        action={[
                            <IconButton
                                key="close"
                                aria-label="Close"
                                color="inherit"
                                onClick={this.handleCloseA}
                            >
                                <CloseIcon />
                            </IconButton>,
                        ]}
                    />
                    <img style={{ height: 100 + "%", width: 100 + "%", backgroundColor: "#00a9e7" }} src={background} alt="background"></img>
                </Card>
            </React.Fragment>
        )

        var message = (
            <React.Fragment>
                <Dialog style={{ fontFamily: "Lato" }}
                    open={this.state.open1}
                    onClose={this.handleCloseOpen}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle style={{ boxSizing: 'border-box', background: "linear-gradient(60deg, #52607c	, #96b0cb)", textAlign: "center", marginTop: -5, paddingTop: 20, paddingBottom: 15 }} id="alert-dialog-title"><span style={{ color: 'white', fontSize: 26 }}>{"Alert!"}</span></DialogTitle>
                    <DialogContent style={{ marginTop: 10 }}>
                        <DialogContentText id="alert-dialog-description">
                            {errmsg}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseOpen} color="primary">
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        )

        if (isMobile) {
            return (
                <div style={{ fontFamily: "Lato" }}>
                    <Paper style={{ backgroundColor: "rgb(27, 37, 57)", borderRadius: 0, position: "fixed", width: 100 + "%", height: 100 + "%" }}>
                        {popup}
                        <Card style={{ position: "absolute", marginLeft: 5 + "%", marginRight: "auto", width: 85 + "%", height: 62 + "%", top: 17 + "%", backgroundColor: "white", verticalAlign: "middle", paddingTop: 5 + "vh", paddingRight: 3 + "%", paddingLeft: 3 + "%", textAlign: "left", borderRadius: 20 }}>
                            {form}
                        </Card>
                        {message}
                        <LoginFooter/>
                    </Paper>
                </div>
            );
        } else {
            return (
                <div style={{ fontFamily: "Lato" }}>
                    <Paper style={{ backgroundColor: "rgb(27, 37, 57)", borderRadius: 0, position: "fixed", width: 100 + "%", height: 100 + "%" }}>
                        {popup}
                        <OMNIInfo/>
                        <Card style={{ position: "absolute", marginLeft: 45 + "%", marginRight: "auto", width: 32 + "%", height: 60 + "%", top: 13 + "%", backgroundColor: "white", verticalAlign: "middle", paddingTop: 5 + "vh", paddingRight: 3 + "%", paddingLeft: 3 + "%", textAlign: "left", borderRadius: 20 }}>
                            {form}
                        </Card>
                        {message}
                        <LoginFooter/>
                    </Paper>
                </div>
            );
        }
    }

}

ConfirmUser.propTypes = {
    classes: PropTypes.object.isRequired,
};



export default withStyles(styles)(ConfirmUser);