const initialState = {
    workflows : [],
    workflow_map:[]
}

const reducer = (state=initialState, action) => {
    switch(action.type){
        case "SAVE_BILLABLE_WORKFLOWS":
            let [workflows, workflow_map] = action.workflows
            return {
                workflows : workflows,
                workflow_map : workflow_map
            }
        default:
            return state
    }
}

export default reducer;