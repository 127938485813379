import apigClientFactory from '../chalice-javascript-sdk/apigClient';

export const getCUSTUIConstant = async (params, apiurl=undefined) => {
    var apigClient = apigClientFactory.newClient(undefined, apiurl);
    var result = {}
    var paramsTmp = {
        parameter: params,
    }
    
    result =  await apigClient.awsopsGetCUSTCUIConstantParameterGet(paramsTmp, '', '').then(result => {
        return result
    }).catch(error=>{
    });

    return result
}