import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Backdrop, CircularProgress, Card, Button, Paper, TextField, ButtonBase, Snackbar, IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import apigClientFactory from './chalice-javascript-sdk/apigClient';
// import loginlist from './loginList.png';
import background from './loginBackground blue.png';
import { isMobile } from "react-device-detect";
import CloseIcon from '@material-ui/icons/Close';
import {returnAccess} from './utility/returnAccess'

import LoginFooter from './components/Footer/LoginFooter';
import PasswordInput from './PasswordInput';
import OMNIInfo from './components/Global/OMNIInfo';
import {Link} from 'react-router-dom';

import {dropCookie} from './utility/dropCookie';
import {checkCookie} from './utility/checkCookie';

import * as config from "./Config/config";
import queryString from 'query-string'
import * as SS from './utility/secureStorage';
import {readAccess} from './utility/readAccess';
import {resetStorage} from './utility/resetStorage'

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
});


function appendReverseKeyValuePairs(workflows){
    let wft = {...workflows}

    Object.keys(wft).forEach((key)=>{
        workflows[wft[key]] = key
    })
}


class LoginPage extends React.Component {
    state = {
        username: '',
        password: '',
        isdev: false,
        isQA: false,
        isIreland: false,
        rememberlogin: true,
        open: checkCookie('vlomni-cookie', 'true')?false:true,
        open1: false,
        isCanadadev: false,
        errmsg: '',
        backdrop: false,
        openSPAuth: false
    };

    endPoints =  config.createConfig().isProd ? config.prodEndPoints : config.devEndPoints

    componentDidMount() {
        // this.setState({ username: localStorage.getItem("memoryuser"), password: localStorage.getItem("memorypassword") })
        // Only remember recent id used.
        if (this.props.location.from && this.props.location.from.pathname){
            this.setState({username: this.props.location.email })
        }else{
            this.setState({ username: localStorage.getItem("memoryuser")?localStorage.getItem("memoryuser"):''})
        }
    }

    insertLog = async(customerid, username, succeeded=1, error=null) => {
        var apigClient = apigClientFactory.newClient();

        var paramsTmp = {
            CustomerID: customerid,
        }
        var additionalParamsTmp = {
            headers: {
                "Authorization": localStorage.getItem("accesstoken")
            }
        }    
        
        var requestBody = {
            "loginid": username,
            "succeeded":succeeded,
            "note": error,
            "url" : document.URL,
        }

        return await apigClient.dbopsInsertLogCustomerIDPost(paramsTmp, requestBody, additionalParamsTmp).then(result => {
            return result
        });
    }

    getRegion = async(username, password) => {
        var result;
        for (let region of Object.keys(this.endPoints)){
            localStorage.setItem("apiurl", this.endPoints[region].apiurl);
            localStorage.setItem("bucketurl",  this.endPoints[region].bucketurl);
            localStorage.setItem("bucketname", this.endPoints[region].bucketname);

            result = await this.getCredentials(username, password, this.endPoints[region]).then(result=>{
                return result
            }).catch(error=>{
                //NotAuthorizedException - incorrect password
                //UserNotFoundException - user not found
                if (! error.response) return ;
                if (error.response.data.Error.includes('NotAuthorizedException')) {
                    if (error.response.data.Region) localStorage.setItem("apiurl", this.endPoints[error.response.data.Region].apiurl);
                    throw error
                }
            })
            if (result) return result
        }

    }

    getCredentials = async(username, password, region) => {
        var apiurl = region.apiurl
        var bucketurl = region.bucketurl
        var apigClient = apigClientFactory.newClient(undefined, apiurl);
        var params = {
            username: username,
            password: password,
        }
        return await apigClient.vlauthSigninV2UsernamePasswordPut(params, '', '').then(async (result) => {
            result.data.apiurl = apiurl
            result.data.bucketurl = bucketurl
            if (config.createConfig().isProd && result.data.region !== null && result.data.region in this.endPoints){
                localStorage.setItem("apiurl", this.endPoints[result.data.region].apiurl);
                localStorage.setItem("bucketurl",  this.endPoints[result.data.region].bucketurl);
                localStorage.setItem("bucketname", this.endPoints[result.data.region].bucketname);
                apigClient = apigClientFactory.newClient(undefined, this.endPoints[result.data.region].apiurl);
            }

            var paramsTmp = {
                username: username,
                customername: result.data.CustomerID,
            }
            var additionalParamsTmp = {
                queryParams: {
                    fields: "S3Link, AccountStatus",
                },
                headers: {
                    "Authorization": result.data.IdToken
                }
            }

            let user = await apigClient.dbopsGetUserInfoCustomernameUsernameGet(paramsTmp, '', additionalParamsTmp).then(result=>{
                if (result.data.users.length === 0) throw new Error("User doesn't exist")
                return result.data
            }).catch(error=>{
                throw new Error("User doesn't exist")
            })

            result.data.S3Link = user.users[0].S3Link
            result.data.AccountStatus = user.users[0].AccountStatus
            result.data.workflows = user.workflows

            return result

        }).catch(error=>{
            throw error
        })
    }


    async signIn() {
        const { username, password, rememberlogin } = this.state;
        var customername;

        resetStorage();

        this.setState({backdrop:true})

        if (rememberlogin) {
            localStorage.setItem("memoryuser", username);
        }

        this.getRegion(username, password).then(async(result) =>{
            if ("Error" in result.data) throw new Error(result.data.Error)

            localStorage.setItem("accesstoken", result.data.IdToken);
            localStorage.setItem("updatetoken", result.data.AccessToken);
            localStorage.setItem("refreshtoken", result.data.refreshToken);
            localStorage.setItem("username", username);
            localStorage.setItem("expiresin", result.data.expiresIn);
            
            customername = result.data.CustomerID;
            
            SS.secureStorage_setItem('customername', customername)
            appendReverseKeyValuePairs(result.data.workflows)
            SS.secureStorage_setItem('workflows',  JSON.stringify(result.data.workflows))

            if (result.data.secondary_customerIDs.trim().length > 0){
                SS.secureStorage_setItem('secondary_customerids', customername+","+result.data.secondary_customerIDs)
            }
            else{
                SS.secureStorage_setItem('secondary_customerids', result.data.secondary_customerIDs.trim())
            }
            
            if (result.data.secondary_customerIDs.length > 0){
                SS.secureStorage_setItem('selected_customername', '')
            }else{
                SS.secureStorage_setItem('selected_customername', customername)
            }

            await readAccess().then(result => {
                // localStorage.setItem('access', JSON.stringify(result))
                SS.secureStorage_setItem("database", result.includes('dynamodb')?'dynamodb':'rds')
                SS.secureStorage_setItem('access', JSON.stringify(result))
            })

            if (result.data.S3Link !== undefined) {
                localStorage.setItem("userAvatar", result.data.S3Link);
                localStorage.setItem("main_user_Avatar", result.data.S3Link);
            } else {
                localStorage.setItem("userAvatar", config.default_userAvatar);
                localStorage.setItem("main_user_Avatar", config.default_userAvatar)
            }
            SS.secureStorage_setItem('accountstatus', result.data.AccountStatus)


            var apigClient = apigClientFactory.newClient();

            //***********************ONLY FOR VLOMNI_DEMO***************************
            if (customername === 'VLOMNI_DEMO'){
                var deleteBody = {
                    CustomerID: SS.secureStorage_getItem("customername"),
                    "IsRead":0
                }

                if (config.createConfig().isProd){
                    deleteBody['messageids'] = '1670664,1629741,1633795'
                }
        
                var additionalParamstemp = {
                    headers: {
                        "Authorization": localStorage.getItem("accesstoken")
                    }
                }

        
                await apigClient.dbopsBulkupdatewebservicemessagePut('', deleteBody, additionalParamstemp).then(result => {
                }).catch(function (error) {
                });
            }
            //*********************************************************************

            var paramsTmp = {
                tablename: "WebServiceCustomer",
                customername: customername,
            }
            var additionalParamsTmp = {
                queryParams: {
                    fields: "StoreAdminURL, PaymentGateway, PaymentGatewayID, CompanyID, Status, ID, LongName"
                },
                headers: {
                    "Authorization": localStorage.getItem("accesstoken")
                }
            }

            apigClient.dbopsGetLatestRecordsTablenameCustomernameGet(paramsTmp, '', additionalParamsTmp).then(result => {
                if (result.data.length > 0){
                    if ( result.data[0].StoreAdminURL === null || result.data[0].StoreAdminURL === undefined) {
                        localStorage.setItem("storeadminurl", "null");
                    } else {
                        localStorage.setItem("storeadminurl", result.data[0].StoreAdminURL);
                    }
                    SS.secureStorage_setItem("CompanyName", result.data[0].LongName!==null?result.data[0].LongName:customername)
                    SS.secureStorage_setItem("PaymentGateway", result.data[0].PaymentGateway!==null?result.data[0].PaymentGateway:"")
                    SS.secureStorage_setItem("PaymentGatewayID", result.data[0].PaymentGatewayID!==null?result.data[0].PaymentGatewayID:"")
                    SS.secureStorage_setItem("CompanyID", result.data[0].CompanyID!==null?result.data[0].CompanyID:"")
                    
                    SS.secureStorage_setItem("EDIstatus", result.data[0].Status?result.data[0].Status:"")

                    SS.secureStorage_setItem("CustomerID", result.data[0].ID)

                }
                // this.setState({backdrop:false})

                let now = new Date();
                dropCookie('vlomni-cookie-active', now.getTime(), config.SESSION_EXPIRESIN)
                dropCookie('vlomni-cookie-loggedin', now.getTime(), localStorage.getItem('expiresin'))

                
                var values = queryString.parse(this.props.location.search)
                if ('from' in values && values.from === 'kb'){
                    window.location.replace("https://kb.myvlomni.com");
                }else{
                    this.insertLog(customername, username).finally(()=> window.location = "/")
                    // window.location = "/";
                    // this.props.history.push("/")
                }
                
            });
        }).catch(error=>{
            //NotAuthorizedException - incorrect password
            //UserNotFoundException - user not found
            try {
                if ('Error' in error.response.data){
                    if (error.response.data.Error.includes("NotAuthorizedException")) this.insertLog(error.response.data.CustomerID, username, 0, 'Incorrect Password')
                }else{
                    this.insertLog(customername, username, 0, JSON.stringify(error.response.data))
                }
            } catch (error) {
                // this.insertLog(customername, username, 0, error.message)
            }

            this.setState({backdrop:false, open1:true, errmsg:"Wrong username or password!"})
        })

    }

    handleUsernameChange = (event) => {
        this.setState({ username: event.target.value.toLowerCase() });
    }

    handlePasswordChange = (event) => {
        this.setState({ password: event.target.value });
    }

    handleDevChange = (event) => {
        this.setState({ isdev: event.target.value, isIreland: false, isCanadadev: false, isQA: false });
    }

    handleIrChange = (event) => {
        this.setState({ isIreland: event.target.value, isdev: false, isCanadadev: false, isQA: false });
    }

    handleCaChange = (event) => {
        this.setState({ isCanadadev: event.target.value, isdev: false, isIreland: false, isQA: false });
    }

    handleQaChange = (event) => {
        this.setState({ isQA: event.target.value, isdev: false, isIreland: false, isCanadadev: false });
    }

    handleRemChange = (event) => {
        this.setState({ rememberlogin: event.target.value });
    }

    handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            if (!this.state.password || !this.state.username) return
            this.signIn();
        }
    };

    //For Cookie Alert
    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        
        dropCookie('vlomni-cookie','true', 2592000)
       
        this.setState({ open: false });
    };

    //Not being used
    handleClickA = () => {
        this.setState({ openA: true });
    };

    //Not being used
    handleCloseA = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        this.setState({ openA: false });
    };

    //For Error Alerts
    handleClickOpen = () => {
        this.setState({ open1: true });
    };

    //For Error Alerts
    handleCloseOpen = () => {
        this.setState({ open1: false });
    };

    //Redirect to Forgot Password Page
    redirectToForgotPassword = () => {
        this.props.history.push({
            pathname: '/forgotPassword',
            email: this.state.username
        })
    }

    render() {
        const { username, password, errmsg } = this.state;
        document.title = "VL OMNI Login";

        var loginform = (
            <React.Fragment>
                <h2 style={{ borderRadius: 5, paddingTop: 20, padding: 10, color: "black", margin: "auto", marginBottom: -25, textAlign: "center", fontSize: 1.8 + "em", fontWeight: 400 }}>VL OMNI Login</h2>
                <form autoComplete="new-password">
                    <TextField
                        id="username"
                        label="Username/Email"
                        onChange={this.handleUsernameChange}
                        value={username}
                        onKeyPress={this.handleKeyPress}
                        style={{ marginTop: 5 + 'vh', width: 100 + "%", marginLeft: 'auto', marginRight: 'auto', float: 'left', fontSize: 0.4 + 'em' }}
                    />
                    <PasswordInput
                        id="password"
                        label="Password"
                        onChange={this.handlePasswordChange}
                        onKeyPress={this.handleKeyPress}
                        value={password}
                        style={{ marginTop: 0.5 + 'vh', width: 100 + "%", marginLeft: 'auto', marginRight: 'auto', float: 'left'}}
                    />

                    {/* <FormControlLabel style={{ marginTop: 1 + 'vh', marginLeft: "-2.3%" }} control={<Checkbox value={rememberlogin} onChange={this.handleRemChange} color="primary" />} label="Remember my login" /> */}

                    {/* <FormControlLabel style={{ marginTop: 1 + 'vh', marginLeft: "-2.3%", fontSize: "0.4em" }} control={<Checkbox value={isdev} onChange={this.handleDevChange} color="primary" />} label="DEV" />

                    <FormControlLabel style={{ marginTop: 1 + 'vh', marginLeft: "-2.3%" }} control={<Checkbox value={isCanadadev} onChange={this.handleIrChange} color="primary" />} label="IR PROD" />

                    <FormControlLabel style={{ marginTop: 1 + 'vh', marginLeft: "-2.3%" }} control={<Checkbox value={isIreland} onChange={this.handleCaChange} color="primary" />} label="Canada DEV" />

                        <FormControlLabel style={{ marginTop: 1 + 'vh', marginLeft: "-2.3%" }} control={<Checkbox value={isQA} onChange={this.handleQaChange} color="primary" />} label="QA" /> */}
                    <Button onClick={!password || !username ? null : this.signIn.bind(this)} variant="contained" style={{ backgroundColor: "#00a9e7", color: "white", margin: 'auto', width: 100 + "%", fontSize: 20, fontFamily: "Lato", boxShadow: 'none', marginTop: 1.5 + 'vh' }}>
                        Login
                    </Button>
                </form>
                {/* <div style={{ textAlign: "center", marginTop: 3 + "%", fontSize: 0.8 + 'em', marginBottom: '-10px' }}><a href="/recoverPassword" style={{ textDecoration: "none" }}>Forgot your password?</a></div> */}
                <div style={{ textAlign: "center", marginTop: 3 + "%", fontSize: 0.8 + 'em' }}>
                    <div>Forgot your password? <span onClick={this.redirectToForgotPassword} style={{ color: "#00a9e7", textDecoration: "underline", cursor:'pointer' }}>Click here.</span></div>
                    <div>Don't have an account? <Link to="/newUser" style={{ color: "#00a9e7", textDecoration: "underline" }}>Click here </Link> or contact support@virtuallogistics.ca</div>
                </div>
            </React.Fragment>
        )

        var popup = (
            <React.Fragment>
                <Card style={{ position: "absolute", width: 100 + "%", height: 100 + "%", top: 0 + "%", backgroundColor: "rgb(17, 20, 27)" }}>
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        open={this.state.open}
                        autoHideDuration={999999}
                        onClose={this.handleClose.bind(this)}
                        ContentProps={{
                            'aria-describedby': 'message-id',
                        }}
                        message={<span id="message-id">The Site uses cookies. By using this website, you agree to our use of cookies.</span>}
                        action={[
                            <ButtonBase key="close" onClick={this.handleClose.bind(this)} style={{ color: "black", backgroundColor: "#ffd42d", padding: 5, borderRadius: 5 }}>
                                I Understand
                            </ButtonBase>,
                        ]}
                    />
                    <Snackbar style={{ marginTop: "18%" }}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        open={this.state.openA}
                        onClose={this.handleCloseA}
                        ContentProps={{
                            'aria-describedby': 'message-id',
                        }}
                        message={<span id="message-id">Please make sure your username is entered. An email has been sent to your account with a verification code.</span>}
                        action={[
                            <IconButton
                                key="close"
                                aria-label="Close"
                                color="inherit"
                                onClick={this.handleCloseA}
                            >
                                <CloseIcon />
                            </IconButton>,
                        ]}
                    />
                    <img style={{ height: 100 + "%", width: 100 + "%", backgroundColor: "#00a9e7" }} src={background} alt="Background"></img>
                </Card>                
            </React.Fragment>
        )
        
        var dialog = (
            <React.Fragment>
                <Dialog style={{ fontFamily: "Lato" }}
                    open={this.state.open1}
                    onClose={this.handleCloseOpen}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle style={{ background: "linear-gradient(60deg, #52607c	, #96b0cb)", textAlign: "center", marginTop: -5, paddingTop: 20, paddingBottom: 15 }} id="alert-dialog-title"><span style={{ color: 'white', fontSize: 26 }}>{"Alert!"}</span></DialogTitle>
                    <DialogContent style = {{marginTop: 10}}>
                        <DialogContentText id="alert-dialog-description">
                            {errmsg}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseOpen} color="primary">
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        )

        var backdrop = (
            <React.Fragment>
                    <Backdrop style={{zIndex:"50", backgroundColor:"rgba(0,0,0,0.5)"}} open={this.state.backdrop}>
                    </Backdrop>
                    <div style={{ position:"absolute", zIndex:100, top:"50%", left:"50%", transform:"translate(-50%, -50%)"}} >
                        <CircularProgress position={{color:'white'}} size="9em" />
                    </div>
            </React.Fragment>
        )

        if (isMobile) {
            return (
                <div style={{ fontFamily: "Lato" }}>
                    {this.state.backdrop?backdrop:null}
                    <Paper style={{ backgroundColor: "rgb(27, 37, 57)", borderRadius: 0, position: "fixed", width: 100 + "%", height: 100 + "%" }}>
                        {popup}
                        <Card style={{ position: "absolute", marginLeft: 5 + "%", marginRight: "auto", width: 85 + "%", height: 60 + "%", top: 17 + "%", backgroundColor: "white", verticalAlign: "middle", paddingTop: 5 + "vh", paddingRight: 3 + "%", paddingLeft: 3 + "%", textAlign: "left", borderRadius: 20 }}>
                            {loginform}
                        </Card>
                        {dialog}
                        <LoginFooter/>
                    </Paper>
                </div>
            );
        } else {
            return (
                <div style={{ fontFamily: "Lato" }}>
                    {this.state.backdrop?backdrop:null}
                    <Paper style={{ backgroundColor: "rgb(27, 37, 57)", borderRadius: 0, position: "fixed", width: 100 + "%", height: 100 + "%" }}>
                        {popup}
                        <OMNIInfo/>
                        <Card style={{ position: "absolute", marginLeft: 45 + "%", marginRight: "auto", width: 32 + "%", height: 49 + "%", top: 19 + "%", backgroundColor: "white", verticalAlign: "middle", paddingTop: 5 + "vh", paddingRight: 3 + "%", paddingLeft: 3 + "%", textAlign: "left", borderRadius: 20 }}>
                            {loginform}
                        </Card>
                        {dialog}
                        <LoginFooter/>
                    </Paper>
                </div>
            );
        }
    }

}

LoginPage.propTypes = {
    classes: PropTypes.object.isRequired,
};



export default withStyles(styles)(LoginPage);