import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

function ErrorMessage() {
    return (
        <div style={{padding:'2rem'}}>
            <h2 style={{margin:0}}><FontAwesomeIcon style={{fontSize:'1.2em'}} icon={faExclamationCircle} /></h2>
            <h2 style={{margin:0}}>
                 An error has occurred
            </h2>
            <p>
                Please <Link to='/' style={{textDecoration:'underline'}}>click here</Link> to go back to Dashboard.
            </p>   
            
        </div>
    )
}

export default ErrorMessage;