import { InputAdornment, withStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
// import { RemoveRedEye } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const objectWithoutKey = (object, key) => {
  const {[key]: deletedKey, ...otherKeys} = object;
  return otherKeys;
}


const styles = theme => ({
  // eye: {
  //   cursor: 'pointer',
  // },
});

class PasswordInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      passwordIsMasked: true,
    };
  }

  componentDidUpdate = (preProps, preState) => {
    if (preProps.ismasked !== this.props.ismasked){
      // console.log('updated', this.props.ismasked)
      this.setState({passwordIsMasked: this.props.ismasked === 'true'})
    }
    
  }

  togglePasswordMask = () => {
    this.setState(prevState => ({
      passwordIsMasked: !prevState.passwordIsMasked,
    }));
  };

  render() {
    // const { classes } = this.props;
    const { passwordIsMasked } = this.state;
    // console.log(objectWithoutKey(this.props, 'maskfunc'))

    return (
      <TextField
        type={passwordIsMasked ? 'password' : 'text'}
        {...objectWithoutKey(this.props, 'maskfunc')}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={this.props.maskfunc?this.props.maskfunc:this.togglePasswordMask}
                >
                  {passwordIsMasked ? <VisibilityOff /> : <Visibility />}
                </IconButton>
            </InputAdornment>
          ),
        }}
      />
    );
  }
}

PasswordInput.propTypes = {
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  // value: PropTypes.func.isRequired,
};

export default withStyles(styles)(PasswordInput);