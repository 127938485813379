import React from 'react';
import {getCUSTUIConstant} from '../../utility/getCUSTUIConstant';
import * as config from '../../Config/config';

class PlatformVersion extends React.Component {
  _isMounted = false

  state = {
    version : sessionStorage.getItem('vlomni_custui_platformversion')
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount(){
    this._isMounted = true

    if (!this.state.version){
      let apiurl = config.createConfig().isProd ? config.prodEndPoints['us-east-1'].apiurl : config.devEndPoints['us-east-1'].apiurl

      getCUSTUIConstant('Footer', apiurl).then(result => {
        sessionStorage.setItem('vlomni_custui_platformversion', result.data['Footer'])
        if(this._isMounted) this.setState({version:result.data['Footer']})
      }).catch(error=>{
      })
    }
  }

  render(){
    return <React.Fragment>{this.state.version}</React.Fragment>;
  }
  
}

export default PlatformVersion;