export const checkCookie = (key, value) => {
    // var temp =document.cookie.split(';').map(value => {
    //     return value.trim().split('=')
    // })

    for (let keyvalue of document.cookie.split(';')){
        let [tkey, tvalue] = keyvalue.trim().split('=')
        if (key===tkey && value===tvalue){
            return true
        }
    }

    return false
}