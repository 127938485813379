import React from 'react';
import {getCUSTUIConstant} from '../../utility/getCUSTUIConstant';
import * as config from '../../Config/config';

class CopyRight extends React.Component {
  _isMounted = false

  state = {
    copyright : sessionStorage.getItem('vlomni_custui_copyright')
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount(){
    this._isMounted = true

    if (!this.state.copyright){
      let apiurl = config.createConfig().isProd ? config.prodEndPoints['us-east-1'].apiurl : config.devEndPoints['us-east-1'].apiurl

      getCUSTUIConstant('Copyright', apiurl).then(result => {
        sessionStorage.setItem('vlomni_custui_copyright', result.data['Copyright'])
        if(this._isMounted) this.setState({copyright:result.data['Copyright']})
      }).catch(error=>{
      })
    }
  }


  render() {
    return <React.Fragment>{this.state.copyright}</React.Fragment>;
  }
  
}

export default CopyRight;