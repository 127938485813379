import apigClientFactory from '../chalice-javascript-sdk/apigClient';
import * as config from '../Config/config';

export const checkUsername = async (username) => {
    var dev_pool = false;
    var prod_pool = false;
    var apigClient = apigClientFactory.newClient(undefined, config.devEndPoints['us-east-1'].apiurl);

    var params = {
        username: username,
    }

    dev_pool =  await apigClient.vlauthCheckUsernameUsernameGet(params,'','').then(result=>{
        return true
    }).catch(error=>{
        return false
    })

    //check only if it's PROD
    if (config.createConfig().isProd){
        apigClient = apigClientFactory.newClient(undefined, config.prodEndPoints['us-east-1'].apiurl);

        prod_pool =  await apigClient.vlauthCheckUsernameUsernameGet(params,'','').then(result=>{
            return true
        }).catch(error=>{
            return false
        })
    }

    return dev_pool || prod_pool

}