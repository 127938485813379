import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Card, Button, Paper, TextField, ButtonBase, Snackbar, IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import apigClientFactory from './chalice-javascript-sdk/apigClient';
// import loginlist from './loginList.png';
import background from './loginBackground blue.png';
import { isMobile } from "react-device-detect";
import CloseIcon from '@material-ui/icons/Close';

import LoginFooter from './components/Footer/LoginFooter';
import PasswordInput from './PasswordInput';
import OMNIInfo from './components/Global/OMNIInfo';

import {Link} from 'react-router-dom';
import {checkCookie} from './utility/checkCookie';

import * as config from './Config/config';


const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
});


class ForgotPassword extends React.Component {
    state = {
        username: '',
        confirmcode: '',
        newPassword: '',
        open: checkCookie('vlomni-cookie', 'true')?false:true,
        open1: false,
        flag: false,
        errmsg: '',
        formheight:0
    };

    wd = React.createRef();
    endPoints = config.createConfig().isProd?config.prodEndPoints:config.devEndPoints;

    componentDidMount() {
        this.setState({username: this.props.location.email?this.props.location.email:''})
        // console.log(this.wd.current.offsetHeight)
        this.setState({formheight: this.wd.current.offsetHeight + 230})
    }

    updatePassword = (event) => {
        const { username, newPassword, confirmcode } = this.state;

        var apigClient = apigClientFactory.newClient();

        var body = {
            username: username,
            ConfirmationCode: confirmcode,
            NewPassword: newPassword,
        }

        apigClient.vlauthConfirmForgotPasswordPut('', body, '').then(result => {

            this.setState({ open1: true });
            this.setState({flag: true})
            this.setState({ errmsg: "Successfully changed password! Please return to the login screen." });

        }).catch(error => {
            //alert(JSON.stringify(error));
            this.setState({ open1: true });
            if ( error.response === null || error.response === undefined){
                this.setState({ errmsg: "Error, please check the entered email and verificatioin code to make sure they are both entered correctly!"});
            } else {
                // console.log(error.response)
                this.setState({ errmsg: error.response.data.Error});
            }
            
        });

    }

    getRegion = async(username) => {
        var apigClient;
        var additionalParams;
        var result;
        for (let region of Object.keys(this.endPoints)){
            apigClient = apigClientFactory.newClient(undefined, this.endPoints[region].apiurl);
            additionalParams = {
                queryParams: {
                    username: username
                }
            }
            result = await apigClient.cognitoGetRegionCgGet('','', additionalParams).then(result=>{
                localStorage.setItem("apiurl", this.endPoints[region].apiurl);
                return result
            }).catch(error=>{
            })
            if (result) return result
        }
    }

    resendConfirmCode = async(event) => {

        const { username } = this.state;

        var apigClient = apigClientFactory.newClient(undefined, this.endPoints['us-east-1'].apiurl);

        this.getRegion(username).then(result=>{
            if (result === undefined){
                throw new Error('No Matching Username')
            }
            
            apigClient = apigClientFactory.newClient();

            var body = {
                username: username,
            }
    
            apigClient.vlauthForgotPasswordPut('', body, '').then(result => {
                this.setState({ open1: true });
                this.setState({ errmsg: "A verification code has been sent to your email!" });
    
            }).catch(error => {
                this.setState({ open1: true });
                this.setState({ errmsg: error.response.data.Error});
            });


        }).catch(error=>{
            this.setState({ open1: true });
            this.setState({ errmsg: "Username/email doesn't exist. Please check your username/email and try again."});
        })
    }

    handleUsernameChange = (event) => {
        this.setState({ username: event.target.value });
    }

    handleCodeChange = (event) => {
        this.setState({ confirmcode: event.target.value });
    }

    handlePasswordConfirm = (event) => {
        this.setState({ newPassword: event.target.value });
    }

    handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            this.updatePassword();
        }
    };

    handleKeyPress2 = (e) => {
        if (e.key === 'Enter') {
            this.resendConfirmCode();
        }
    };    

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        this.setState({ open: false });
    };

    handleClickA = () => {
        this.setState({ openA: true });
    };

    handleCloseA = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        this.setState({ openA: false });
    };

    handleClickOpen = () => {
        this.setState({ open1: true });
    };

    handleCloseOpen = () => {
        this.setState({ open1: false });
        if (this.state.flag){
            this.props.history.push({
                pathname: '/login',
                email: this.state.username,
                from: this.props.location
            })           
        }
    };

    render() {
        const { username, errmsg, confirmcode, newPassword, formheight } = this.state;
        document.title = "VL OMNI Recover Password";

        var form = (
            <div ref={this.wd}>
                <h2 style={{ borderRadius: 5, color: "black", margin: "auto", marginBottom: -15, textAlign: "center", fontSize: 1.8 + "em", fontWeight: 400 }}>VL OMNI Account Recovery</h2>
                <form autoComplete="new-password">
                    <TextField
                        id="email"
                        label="Username/Email"
                        onChange={this.handleUsernameChange}
                        value={username}
                        onKeyPress={this.handleKeyPress2}
                        style={{ marginTop: 5 + 'vh', width: 100 + "%", marginLeft: 'auto', marginRight: 'auto', float: 'left', fontSize: 0.4 + 'em' }}
                    />
                    <Button type = 'button'  onClick={this.resendConfirmCode} variant="contained" style={{ backgroundColor: "#00a9e7", color: "white", margin: 'auto', width: 100 + "%", fontSize: 20, fontFamily: "Lato", boxShadow: 'none', marginTop: 1.5 + 'vh' }}>
                        Send Confirmation Code
                    </Button>
                    <TextField
                        id="accessToken"
                        label="Confirmation Code"
                        onChange={this.handleCodeChange}
                        value={confirmcode}
                        onKeyPress={this.handleKeyPress}
                        style={{ marginTop: 0.5 + 'vh', width: 100 + "%", marginLeft: 'auto', marginRight: 'auto', float: 'left', fontSize: 0.4 + 'em' }}
                    />
                    <PasswordInput
                        id="newPassword"
                        label="New Password"
                        onChange={this.handlePasswordConfirm}
                        value={newPassword}
                        onKeyPress={this.handleKeyPress}
                        style={{ marginTop: 0.5 + 'vh', width: 100 + "%", marginLeft: 'auto', marginRight: 'auto', float: 'left' }}
                    />
                    {/* <Tooltip title="Your password must be at least 8 characters long, have at least 1 number, 1 special character, 1 uppercase and 1 lowercase letter. " placement="top">
                    <IconButton style = {{float: "right", marginTop: -70}}><Icon>help_outline</Icon></IconButton>
                    </Tooltip> */}

                    <Button type = 'button'  onClick={this.updatePassword} variant="contained" style={{ backgroundColor: "#00a9e7", color: "white", margin: 'auto', width: 100 + "%", fontSize: 20, fontFamily: "Lato", boxShadow: 'none', marginTop: 1.5 + 'vh' }}>
                        Change Password
                    </Button>
                </form>
                {/* <div style={{ textAlign: "center", marginTop: 3 + "%", fontSize: 0.8 + 'em', marginBottom: '-10px' }}><a href="/recoverPassword" style={{ textDecoration: "none" }}>Forgot your password?</a></div> */}
                <div style={{ textAlign: "center", marginTop: 3 + "%", fontSize: 0.8 + 'em' }}>
                    <div>Don’t have an account? <Link to="/newUser" style={{ color: "#00a9e7", textDecoration: "underline" }}>Click here.</Link></div> 
                    <div>Still having trouble? Contact support@virtuallogistics.ca. </div>
                    <div>Want to return to the Login page? <Link to="/login" style={{ color: "#00a9e7", textDecoration: "underline" }}>Click here. </Link></div>
                </div>
            </div>
        )

        var popup = (
            <React.Fragment>
                <Card style={{ position: "absolute", width: 100 + "%", height: formheight, top: 0 + "%", backgroundColor: "rgb(17, 20, 27, 0)", boxShadow:'none'}}>
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        open={this.state.open}
                        autoHideDuration={999999}
                        onClose={this.handleClose.bind(this)}
                        ContentProps={{
                            'aria-describedby': 'message-id',
                        }}
                        message={<span id="message-id">Enter your Email and click the send code button first to receive your confirmation code.</span>}
                        action={[
                            <ButtonBase key="close" onClick={this.handleClose.bind(this)} style={{ color: "black", backgroundColor: "#ffd42d", padding: 5, borderRadius: 5 }}>
                                I Understand
                            </ButtonBase>,
                        ]}
                    />
                    <Snackbar style={{ marginTop: "50%" }}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        open={this.state.openA}
                        onClose={this.handleCloseA}
                        ContentProps={{
                            'aria-describedby': 'message-id',
                        }}
                        message={<span id="message-id">Please make sure your username is entered. An email has been sent to your account with a verification code.</span>}
                        action={[
                            <IconButton
                                key="close"
                                aria-label="Close"
                                color="inherit"
                                onClick={this.handleCloseA}
                            >
                                <CloseIcon />
                            </IconButton>,
                        ]}
                    />
                    {/* <img style={{ height: 100 + "%", width: 100 + "%", backgroundColor: "#00a9e7" }} src={background}></img> */}
                </Card>
            </React.Fragment>
        )

        var message = (
            <React.Fragment>
                <Dialog style={{ fontFamily: "Lato"}}
                    open={this.state.open1}
                    onClose={this.handleCloseOpen}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle style={{ background: "linear-gradient(60deg, #52607c	, #96b0cb)", textAlign: "center", marginTop: -5, paddingTop: 20, paddingBottom: 15 }} id="alert-dialog-title"><span style={{ color: 'white', fontSize: 26 }}>{"Alert!"}</span></DialogTitle>
                    <DialogContent style={{ marginTop: 10 }}>
                        <DialogContentText id="alert-dialog-description">
                            {errmsg}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseOpen} color="primary">
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        )

        if (isMobile) {
            return (
                // <div style={{ fontFamily: "Lato" }}>
                //     <Paper style={{ backgroundColor: "rgb(27, 37, 57)", borderRadius: 0, position: "fixed", width: 100 + "%", height: 100 + "%" }}>
                //         {popup}
                //         <Card style={{ position: "absolute", marginLeft: 5 + "%", marginRight: "auto", width: 85 + "%", height: 66 + "%", top: 15.5 + "%", backgroundColor: "white", verticalAlign: "middle", paddingTop: 5 + "vh", paddingRight: 3 + "%", paddingLeft: 3 + "%", textAlign: "left", borderRadius: 20 }}>
                //             {form}
                //         </Card>
                //         {message}
                //         <LoginFooter/>
                //     </Paper>
                // </div>  
            <div style={{ fontFamily: "Lato", width: '100%', height: '100vh' }}>
                <Paper style={{ backgroundColor: "rgb(27, 37, 57, 0)", borderRadius: 0, position: "fixed", width: 100 + "%", height: 100 + "%", overflow:"auto"}}>
                    {popup}
                    <Card style={{ position: "absolute", marginLeft: 5 + "%", marginRight: "auto", padding: "5%", width: 85 + "%", top: 15.5 + "%", backgroundColor: "white", verticalAlign: "middle", paddingTop: 5 + "vh", paddingRight: 3 + "%", paddingLeft: 3 + "%", textAlign: "left", borderRadius: 20 }}>
                        {form}
                    </Card>
                    {message}
                    
                </Paper>
                <LoginFooter styles={{}}/>
                <img style={{ height: 100 + "%", width: 100 + "%", backgroundColor: "#00a9e7" }} src={background} alt="Background"></img>     
            </div> 
                   
            );
        } else {
            return (
                <div style={{ fontFamily: "Lato" }}>
                    <Paper style={{ backgroundColor: "rgb(27, 37, 57)", borderRadius: 0, position: "fixed", width: 100 + "%", height: 100 + "%" }}>
                        {popup}
                        <OMNIInfo/>
                        <Card style={{ position: "absolute", marginLeft: 45 + "%", marginRight: "auto", width: 32 + "%", height: 66 + "%", top: 11 + "%", backgroundColor: "white", verticalAlign: "middle", paddingTop: 5 + "vh", paddingRight: 3 + "%", paddingLeft: 3 + "%", textAlign: "left", borderRadius: 20 }}>
                                {form}
                        </Card>
                        {message}
                        <LoginFooter/>
                        <img style={{ height: 100 + "%", width: 100 + "%", backgroundColor: "#00a9e7" }} src={background} alt="Background 2"></img>
                    </Paper>
                </div>
            );
        }
    }

}

ForgotPassword.propTypes = {
    classes: PropTypes.object.isRequired,
};



export default withStyles(styles)(ForgotPassword);