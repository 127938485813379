//Created on July 9th, 2021
//Updated on January 19th, 2023
//Author: Vaibhavi More
import React from 'react';
import queryString from 'query-string'
import { withStyles } from '@material-ui/core/styles';
import { isMobile } from "react-device-detect";
import Typography from '@material-ui/core/Typography';
import { Button, Paper, Backdrop } from '@material-ui/core';
import background from '../../src/loginBackgroundBlueBase.png';
import amzn_sellercentral_logo from './sellercentral_logo.png';
import amzn_vendorcentral_logo from './vendorcentral_logo.png';
import { secureStorage_setItem, secureStorage_getItem } from '../utility/secureStorage';
import * as config from '../Config/config';
import { resetStorage } from '../utility/resetStorage';
import apigClientFactory from '../chalice-javascript-sdk/apigClient';


const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    table: {
        minWidth: 500,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
});

//Amazon Seller Central
class Step1 extends React.Component {

    componentDidMount() {
        var tempRegion = 'US'
        var url = config.sellerCentralURL[tempRegion]
        localStorage.setItem("region", tempRegion)
        localStorage.setItem("url", url)

        let regions = config.marketplaceRegion
        Object.keys(regions).forEach(region => {
            if (regions[region].includes("NEMCOR")) {
                tempRegion = region
                url = config.sellerCentralURL[region]
                localStorage.setItem("url", url)
                localStorage.setItem("region", region)
            }
        })

        if (this.props.location["pathname"].includes('amazon-vendor-central')) {
            url = config.vendorCentralURL[tempRegion]
            localStorage.setItem("url", url)
        }
    }

    handleLogout = () => {
        resetStorage()

        this.props.history.push({
            pathname: '/login'
        })
    }

    handleClick = async () => {
        var customername = secureStorage_getItem("customername")

        //determine vendor/seller account
        //default region and url
        var tempRegion = 'US'
        var url = config.sellerCentralURL[tempRegion]
        localStorage.setItem("region", tempRegion)
        localStorage.setItem("url", url)

        let regions = config.marketplaceRegion
        Object.keys(regions).forEach(region => {
            if (regions[region].includes(customername)) {
                tempRegion = region
                url = config.sellerCentralURL[region]
                localStorage.setItem("url", url)
                localStorage.setItem("region", region)
            }
        })

        if (this.props.location["pathname"].includes('amazon-vendor-central')) {
            url = config.vendorCentralURL[tempRegion]
            localStorage.setItem("url", url)
        }

        var app_id = config.amazon_app_id
        var apigClient = apigClientFactory.newClient()
        var accesstoken = localStorage.getItem("accesstoken")

        //change beta after testing
        var tempURL = url + "/apps/authorize/consent?application_id=" + app_id + "&state=" + accesstoken
        //https://sellercentral.amazon.ca/apps/authorize/consent?application_id=amzn1.sp.solution.63fab2fa-81ce-4fc7-8bcd-2eac1fb2d715&version=beta&state=spapistate
        window.location = tempURL

        var body = {
            customerid: secureStorage_getItem("customername"),
            info: "Clicked Authorize me button, navigate to" + tempURL
        }

        var additionalParams = {
            headers: {
                "Authorization": accesstoken
            }
        }

        apigClient.awsopsGenerateLogPut('', body, additionalParams).then(result => {
        }).catch(function (error) {
        });

    }

    handlePopupClose = () => {
        this.handleLogout()
    }

    render() {
        document.title = "VL OMNI";

        var amzn_logo = <img src={amzn_sellercentral_logo} style={{ maxWidth: '200px', maxHeight: '100%', marginLeft: 3 + '%', marginRight: "auto" }} alt="Amazon Seller Central" />
        var disc1 = <Typography variant="body1" style={{ margin: 2 + '%', marginBottom: 2 + '%', color: "#6d6e70", marginTop: 100 + "px", fontWeight: "bold" }}>Disclaimer: By clicking this button you authorize VL OMNI to access your Seller Central data over REST APIs.</Typography>
        var disc2 = <Typography variant="body1" style={{ margin: 2 + '%', marginBottom: 5 + '%', color: "#6d6e70" }}>We will redirect you to Amazon selling APIs consent page where you can grant permissions to VL OMNI app to access your seller central data over REST APIs.</Typography>

        if (this.props.location["pathname"].includes('vlomni-authorize-amazon-vendor-central')) {
            amzn_logo = <img src={amzn_vendorcentral_logo} style={{ maxWidth: '200px', maxHeight: '100%', marginLeft: 3 + '%', marginRight: "auto" }} alt="Amazon Seller Central" />
            disc1 = <Typography variant="body1" style={{ margin: 2 + '%', marginBottom: 2 + '%', color: "#6d6e70", marginTop: 100 + "px", fontWeight: "bold" }}>Disclaimer: By clicking this button you authorize VL OMNI to access your Vendor Central data over REST APIs.</Typography>
            disc2 = <Typography variant="body1" style={{ margin: 2 + '%', marginBottom: 5 + '%', color: "#6d6e70" }}>We will redirect you to Amazon selling APIs consent page where you can grant permissions to VL OMNI app to access your vendor central data over REST APIs.</Typography>
        }

        var content = (
            <div style={{ position: "absolute", top: 30 + "%", left: 50 + '%', marginTop: 5 + '%', verticalAlign: "middle", transform: "translate(-50%, -50%)" }}>
                <img src={config.defaultVLLogo} style={{ maxWidth: '63px', maxHeight: '100%', marginTop: 15 + '%', marginRight: "auto" }} alt="VL OMNI" />
                {amzn_logo}

                <Typography variant="h3" style={{ color: "#1db3e9", fontSize: 30 + "px", marginLeft: 2 + "%", marginRight: "auto", marginTop: 80 + "px", textAlign: "center" }}>WELCOME TO VL OMNI</Typography>
                {disc1}
                {disc2}

                <Button variant="contained"
                    style={{ backgroundColor: "#ed1944", color: "white", fontWeight: "bold", fontFamily: "Lato", marginTop: 10 + 'px', float: "right" }}
                    onClick={this.handleClick}>
                    Authorize Me
                </Button>
            </div>
        )

        var dialog = (
            <div className="time-out-message" style={{ fontFamily: "Lato", display: 'none' }}>
                <Backdrop style={{ zIndex: "10000", backgroundColor: "rgba(0,0,0,0.5)" }} open={true}>
                </Backdrop>
                <div style={{ position: "fixed", zIndex: 10001, top: "50%", left: "50%", transform: "translate(-50%, -50%)", backgroundColor: 'white', borderRadius: '0.5em', padding: '2em' }} >
                    <span id="message-id">Your session has timed out.<br /> <span style={{ textDecoration: "underline", cursor: "pointer", color: "dodgerblue" }} onClick={this.handlePopupClose.bind(this)}>Click here</span> to log in again.</span>
                </div>
            </div>
        )

        if (isMobile) {
            return (
                <div style={{ fontFamily: "Lato" }}>
                    <Paper style={{ backgroundColor: "rgb(27, 37, 57)", borderRadius: 0, position: "fixed", width: 100 + "%", height: 100 + "%" }}>
                        {content}
                        <img style={{ width: 100 + "%", height: 100 + '%', backgroundColor: "#00a9e7" }} src={background} alt="Background"></img>
                    </Paper>
                    {dialog}
                </div>
            )
        } else {
            return (
                <div style={{ fontFamily: "Lato" }}>
                    <Paper style={{ backgroundColor: "rgb(27, 37, 57)", borderRadius: 0, position: "fixed", width: 100 + "%", height: 100 + "%" }}>
                        {content}
                        <img style={{ width: 100 + "%", backgroundColor: "#00a9e7" }} src={background} alt="Background"></img>
                        {dialog}
                    </Paper>
                </div>
            )
        }
    }
}



export default withStyles(styles)(Step1);
