import React from 'react';

class ErrorBoundary extends React.Component {
  
    // componentDidCatch(error, info) {
    //   window.location.href = "/error";
    // }

    // static getDerivedStateFromError(error) {
    //   window.location.href = "/error";
    //   return
    // }

    render() {
      return this.props.children;
    }
  }

export default ErrorBoundary