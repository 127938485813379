export const SESSION_EXPIRESIN = 1800

export const createConfig = () =>{
    var isProd = true

    if (isProd){
        return {
            isProd : true,
        }
    }

    return {
        isProd : false,
    }
}

export const limit = 500

export const default_userAvatar = "https://customerui-profile-icons.s3.amazonaws.com/0/vlomni.png"

export const prodEndPoints = {
    'us-east-1':{
        apiurl : "https://ndgq84taa0.execute-api.us-east-1.amazonaws.com/prod",
        bucketurl : "customerui-prod",
        bucketname: "vlomni-prod"
    },
    'ca-central-1':{
        //on dev account
        apiurl : "https://jxhkdgxkka.execute-api.ca-central-1.amazonaws.com/api",
        bucketurl : "customerui-ca",
        bucketname: "vlomni-dev-ca"
    },
    'eu-west-1':{
        apiurl : "https://9jp9g7227c.execute-api.eu-west-1.amazonaws.com/prod/",
        bucketurl : "customerui-prod-ir",
        bucketname: "vlomni-prod-ir"
    },
}

export const devEndPoints = {
    'us-east-1':{
        apiurl : "https://hlgmbcgbnd.execute-api.us-east-1.amazonaws.com/api",
        bucketurl : "customerui",
        bucketname: "vlomni-dev"
    },
    'ca-central-1':{
        //on dev account
        apiurl : "https://jxhkdgxkka.execute-api.ca-central-1.amazonaws.com/api",
        bucketurl : "customerui-ca",
        bucketname: "vlomni-dev-ca"
    },
}

export const marketPlaceID = {
    'Canada': "A2EUQ1WTGCTBG2",
    'US': "ATVPDKIKX0DER",
    'Mexico': "A1AM78C64UM0Y8",
    'Brazil': "A2Q3Y263D00KWC",
    'UK': "A1F83G8C2ARO7P"
}

export const sellerCentralURL = {
    'Canada': "https://sellercentral.amazon.ca",
    //on dev account
    'US': "https://sellercentral.amazon.com",
    'Mexico': "https://sellercentral.amazon.com.mx",
    'Brazil': "https://sellercentral.amazon.com.br",
    'UK': "https://sellercentral-europe.amazon.com",
    
}

export const vendorCentralURL = {
    'Canada': "https://vendorcentral.amazon.ca",
    //on dev account
    'US': "https://vendorcentral.amazon.com",
    'Mexico': "https://vendorcentral.amazon.com.mx",
    'Brazil': "https://vendorcentral.amazon.com.br",
    'UK': "https://vendorcentral.amazon.co.uk"
}

export const marketplaceRegion =  {
    "UK":["ANTLER_UK"],
    "Canada": ["NEMCOR", "ECOBEE_CA"]
}

export const amazon_app_id='amzn1.sp.solution.04032b85-e19b-4316-9288-49411ee7b3ee'
export const omni_trust_center = "https://vlomni.com/omni-trust-center/"
export const defaultVLLogo = "https://customerui.s3.amazonaws.com/Resources/Images/NavLogo.jpg"

export const vlomni_logo_connectors = ["PLD_GEN"]