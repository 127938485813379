const initialState = {
    access:null
}

const reducer = (state=initialState, action) => {
    switch(action.type){
        case "ACCESS_RENEWAL":
            return {
                access:action.access
            }
        default:
            return state
    }
}

export default reducer;