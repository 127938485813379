import React from 'react';
import { Card } from '@material-ui/core';

function OMNIInfo() {
    return (
        <Card style={{ overflow: "visible", position: "absolute", marginLeft: 23 + "%", width: 23 + "%", height: 62 + "%", top: 15 + "%", backgroundColor: "#00a9e7", color: "white" }}>
            <img style={{ marginTop: -40, height: 20 + "%", backgroundColor: "white", padding: 15, marginLeft: 33 + "%", borderRadius: 10 }} src="https://s3.amazonaws.com/customerui-profile-icons/VLOmni-600x600.png" alt="VLOmni-600x600.png"></img>
            <div>
                <p style={{ fontWeight: "bold", fontSize: 20, textAlign: "center" }}>Streamline your business</p>
                <p style={{ fontSize: 18, marginLeft: "23%" }}>☑ iPaaS</p>
                <p style={{ fontSize: 18, marginLeft: "23%" }}>☑ Point-to-multipoint</p>
                <p style={{ fontSize: 18, marginLeft: "23%" }}>☑ Agile, scalable & elastic</p>
                <p style={{ fontSize: 18, marginLeft: "23%" }}>☑ Applied & automated business rules</p>
                <p style={{ fontSize: 18, marginLeft: "23%" }}>☑ EU-GDPR Compliant</p>
            </div>
        </Card>
    )
}

export default OMNIInfo;