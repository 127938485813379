const initialState = {
    messages : 0,
    label : '0'
}

const reducer = (state=initialState, action) => {
    switch(action.type){
        case "MESSAGE_RENEWAL":
            return {
                messages : action.messages,
                label : action.messages > 20?'20+':action.messages
            }
        default:
            return state
    }
}

export default reducer;