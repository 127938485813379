import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {Backdrop, CircularProgress,  Card, Button, Paper, TextField,  ButtonBase, Snackbar, IconButton, Dialog, DialogTitle, DialogContent , DialogActions } from '@material-ui/core';
import apigClientFactory from './chalice-javascript-sdk/apigClient';
// import loginlist from './loginList.png';
import PasswordInput from './PasswordInput';
import background from './loginBackground blue.png';
import { isMobile } from "react-device-detect";
import CloseIcon from '@material-ui/icons/Close';
// import Visibility from '@material-ui/icons/Visibility';
// import VisibilityOff from '@material-ui/icons/VisibilityOff';

import LoginFooter from './components/Footer/LoginFooter';
import {Link} from 'react-router-dom';
import OMNIInfo from './components/Global/OMNIInfo';
// import { prototype } from 'events';
import {checkCookie} from './utility/checkCookie';
import {dropCookie} from './utility/dropCookie';
import * as config from './Config/config';
import {checkUsername} from './utility/checkUsername';

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
});


class NewUser extends React.Component {
    state = {
        email: '',
        firstname: '',
        lastname: '',
        company: '',
        region: 'us-east-1',
        secretkey:'',
        password: '',
        gorgiasID: '',
        passwordconfirm: '',
        passwordhint: '',
        open: checkCookie('vlomni-cookie', 'true')?false:true,
        open1: false,
        errmsg: '',
        flag: false,
        formheight: 0,
        passwordIsMasked: true,
        error_fields:[],
        backdrop:false
    };

    wd = React.createRef();

    endPoints = config.createConfig().isProd ? config.prodEndPoints : config.devEndPoints

    componentDidMount() {
        // localStorage.setItem("apiurl", "https://32n3qvjpfj.execute-api.us-east-1.amazonaws.com/api");
        // localStorage.setItem("bucketurl", "customerui");

        this.setState({formheight: this.wd.current.offsetHeight + 150})

    }

    _validateEmail = (email) => {
        // eslint-disable-next-line no-useless-escape
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,}))$/;
        return re.test(String(email).toLowerCase());
    }

    _validateForm = () => {
        let required_fields = ["email", "firstname", "lastname", "secretkey", "password", "passwordconfirm"]
        let error_fields = []
        required_fields.forEach(field => {
            if (field === 'email'){
                if (! this._validateEmail(this.state[field])) error_fields.push(field)
            }else if (this.state[field].length === 0){
                error_fields.push(field)
            }
        })
        return error_fields
        
    }

    async signUp() {
        const {  email, firstname, lastname,  password, passwordconfirm, passwordhint, secretkey } = this.state;

        this.setState({backdrop:true})
        var region;

        let error_fields = this._validateForm()
        if (error_fields.length > 0){
            let errmsg = "Please fill out all required fields with correct formats."
            if (error_fields.length === 1 && error_fields.includes('email') && this.state.email.length > 0){
                errmsg = "Your email address has an invalid email address format. Please correct and try again."
            }

            this.setState({
                error_fields: error_fields,
                flag: false,
                errmsg: errmsg,
                open1:true,
                backdrop:false
            })
            return
        }

        if (password !== passwordconfirm) {
            this.setState({ open1: true });
            this.setState({ errmsg: "Your password and confirmation password do not match." });
            this.setState({ flag: false });
            this.setState({ error_fields: ['passwordconfirm', 'password']})
            this.setState({backdrop:false})
            return
        }

        // check if email exists in the pools (for dev, it will only check dev-us pool. For prod, it will check both dev-us / prod-us)
        if ( await checkUsername(email).then(result=>{
            return result
        }) ) {
            this.setState({
                open1:true,
                flag:false,
                errmsg:"Customer with this email already exists. Please try another email.",
                error_fields: ['email'],
                backdrop:false
            })
            return
        }

        var apigClient = apigClientFactory.newClient(undefined, this.endPoints['us-east-1'].apiurl);

        var additionalParamsTmp = {
            queryParams: {
                secretkey: secretkey
            }
        }

        await apigClient.dbopsGetRegionGet('','', additionalParamsTmp).then(result=>{
            localStorage.setItem("apiurl", this.endPoints[result.data.Region].apiurl);
            region = result.data.Region
            if(region !== 'us-east-1' && region){
                this.setState({region: region})
            }
            
            var body = {
                SecretKey: secretkey,
                Email: email,
                FirstName: firstname,
                LastName: lastname,
                Password: password,
                Timezone: "-5",
                PasswordHint: passwordhint,
                Region:region
            }
    
            apigClient = apigClientFactory.newClient()
    
            apigClient.vlauthSignupPost('', body, '').then(result => {
                if ('Warning' in result.data){
                    let errorMessage = result.data.Warning;
                    error_fields = ["email"]
                    if (["ParamValidationError", "InvalidPasswordException"].includes(result.data.type)){
                        error_fields = ['passwordconfirm', 'password']
                        errorMessage = (
                            <div>
                                Your password does not meet the complexity requirements.<br/>
                                Passwords must be 8 characters or longer and contain one of more of the following:
                                <ul style={{listStyle:'unset'}}>
                                    <li>A number</li>
                                    <li>A special character</li>
                                    <li>An uppercase letter</li>
                                    <li>A lowercase letter</li>
                                </ul>
                            </div>
                        )
                    }
                    else if (result.data.type === 'SecretKeyError') error_fields = ['secretkey']
    
    
                    this.setState({
                        open1:true,
                        flag:false,
                        errmsg:errorMessage,
                        error_fields: error_fields,
                        backdrop:false
                    })
    
                }else{
                    this.setState({ open1: true });
                    this.setState({ errmsg: "You successfully signed up! Please check "+email+" for your verification code." });
                    this.setState({ flag: true });
                    this.setState({error_fields: error_fields});
                    this.setState({ backdrop: false });
                }
    
    
            }).catch(error => {

                this.setState({ open1: true });
                this.setState({errmsg : error.response.data.Error?error.response.data.Error:"Please make sure all fields are entered correctly. Your password must be at least 8 characters long, have at least 1 number, 1 special character, 1 uppercase and 1 lowercase letter."})
                this.setState({ flag: false });
                this.setState({error_fields: error_fields});
                this.setState({ backdrop: false });
            });


        }).catch(error=>{
            console.log(error)
            let error_fields = ['secretkey']
            this.setState({
                open1:true,
                flag:false,
                errmsg:error.response.data.Message,
                error_fields: error_fields,
                backdrop:false
            })
        })



    }

    handleEmailChange = (event) => {
        this.setState({ email: event.target.value.toLowerCase() });
    }

    handleFirstNameChange = (event) => {
        this.setState({ firstname: event.target.value });
    }

    handleLastNameChange = (event) => {
        this.setState({ lastname: event.target.value });
    }

    handleCompanyChange = (event) => {
        this.setState({ company: event.target.value });
    }

    handleIDChange = (event) => {
        this.setState({ gorgiasID: event.target.value });
    }

    handlePasswordChange = (event) => {
        this.setState({ password: event.target.value });
    }

    handlePasswordConfirm = (event) => {
        this.setState({ passwordconfirm: event.target.value });
    }

    handlePasswordHintChange = (event) => {
        this.setState({ passwordhint: event.target.value})
    }

    handleSecretKeyChange = (event) => {
        this.setState({ secretkey: event.target.value})
    }

    handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            this.signUp();
        }
    };

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        
        dropCookie('vlomni-cookie','true', 2592000)
       
        this.setState({ open: false });
    };

    handleClickA = () => {
        this.setState({ openA: true });
    };

    handleCloseA = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        this.setState({ openA: false });
    };

    handleClickOpen = () => {
        this.setState({ open1: true });
    };

    handleCloseOpen = () => {
        this.setState({ open1: false });
    };

    handleconfirmRedirect = () => {
        window.location = "/confirmUser";
    };

    handlePasswordMasking = () => {
        this.setState(prevState => ({
            passwordIsMasked: !prevState.passwordIsMasked,
          }));
    };

    render() {
        const { password, email, firstname, lastname,  passwordconfirm, passwordhint, errmsg, flag, formheight, passwordIsMasked, secretkey } = this.state;
        document.title = "VL OMNI - Sign Up";

        var dialogs = [];

        if (flag === true) {
            dialogs.push(
                <Dialog style={{ fontFamily: "Lato" }}
                    key="close"
                    open={this.state.open1}
                    onClose={this.handleCloseOpen}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle style={{ background: "linear-gradient(60deg, #52607c, #96b0cb)", textAlign: "center", marginTop: -5, paddingTop: 20, paddingBottom: 15 }} id="alert-dialog-title"><span style={{ color: 'white', fontSize: 26 }}>{"Alert!"}</span></DialogTitle>
                    <DialogContent style={{ marginTop: 10 }}>
                        {errmsg}
                    </DialogContent>
                    <DialogActions>
                        {/* <a href="/confirmUser" style={{ padding: 5, color: "#00a9e7" }}>Confirm Login</a> */}
                        <Link to={{
                            pathname: '/confirmUser',
                            email: this.state.email,
                            region: this.state.region
                        }}>Confirm Login</Link>
                    </DialogActions>
                </Dialog>

            );
        } else {
            dialogs.push(
                <Dialog style={{ fontFamily: "Lato" }}
                    key="close"
                    open={this.state.open1}
                    onClose={this.handleCloseOpen}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle style={{ background: "linear-gradient(60deg, #52607c, #96b0cb)", textAlign: "center", marginTop: -5, paddingTop: 20, paddingBottom: 15 }} id="alert-dialog-title"><span style={{ color: 'white', fontSize: 26 }}>{"Alert!"}</span></DialogTitle>
                    <DialogContent style={{ marginTop: 10 }}>
                        {errmsg}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseOpen} color="primary">
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>

            );
        }

        var form = (
            <div ref={this.wd}>
                <h2 style={{ borderRadius: 5, paddingTop: 20, padding: 10, color: "black", margin: "auto", marginBottom: -25, textAlign: "center", fontSize: 1.6 + "em", fontWeight: 400, marginTop: -5 }}>VL OMNI - Account Setup</h2>
                <form autoComplete="new-password" style={{ marginTop: -40 }}>
                    <TextField
                        id="email"
                        label="Email"
                        autoComplete="off"
                        onChange={this.handleEmailChange}
                        value={email}
                        required
                        error={this.state.error_fields.includes('email')}
                        onKeyPress={this.handleKeyPress}
                        style={{ marginTop: 5 + 'vh', width: 100 + "%", marginLeft: 'auto', marginRight: 'auto', float: 'left', fontSize: 0.4 + 'em' }}
                    />
                    <TextField
                        id="firstName"
                        label="First Name"
                        onChange={this.handleFirstNameChange}
                        value={firstname}
                        required
                        error={this.state.error_fields.includes('firstname')}
                        onKeyPress={this.handleKeyPress}
                        style={{ marginTop: 0.5 + 'vh', width: 100 + "%", marginLeft: 'auto', marginRight: 'auto', float: 'left', marginBottom: 0 }}
                    />
                    <TextField
                        id="lastName"
                        label="Last Name"
                        onChange={this.handleLastNameChange}
                        value={lastname}
                        required
                        error={this.state.error_fields.includes('lastname')}
                        onKeyPress={this.handleKeyPress}
                        style={{ marginTop: 0.5 + 'vh', width: 100 + "%", marginLeft: 'auto', marginRight: 'auto', float: 'left', marginBottom: 0 }}
                    />
                    <TextField
                        id="secretkey"
                        label="Secret Key"
                        onChange={this.handleSecretKeyChange}
                        value={secretkey}
                        required
                        error={this.state.error_fields.includes('secretkey')}
                        onKeyPress={this.handleKeyPress}
                        style={{ marginTop: 0.5 + 'vh', width: 100 + "%", marginLeft: 'auto', marginRight: 'auto', float: 'left', marginBottom: 0 }}
                    />

                    <PasswordInput
                        id="password"
                        label="Password"
                        name="password"
                        autoComplete="off"
                        onChange={this.handlePasswordChange}
                        ismasked={passwordIsMasked.toString()}
                        maskfunc={this.handlePasswordMasking}
                        value={password}
                        required
                        error={this.state.error_fields.includes('password')}
                        onKeyPress={this.handleKeyPress}
                        style={{ marginTop: 0.5 + 'vh', width: 100 + "%", marginLeft: 'auto', marginRight: 'auto', float: 'left', marginBottom: 0 }}
                    />

                    {/* <Tooltip title="Your password must be at least 8 characters long, have at least 1 number, 1 special character, 1 uppercase and 1 lowercase letter. " placement="top">
                        <IconButton style = {{float: "right", marginTop: 0}}><Icon>help_outline</Icon></IconButton>
                    </Tooltip> */}

                    <PasswordInput
                        id="passwordConfirm"
                        tabIndex="6"
                        label="Confirm Password"
                        onChange={this.handlePasswordConfirm}
                        ismasked={passwordIsMasked.toString()}
                        maskfunc={this.handlePasswordMasking}
                        value={passwordconfirm}
                        required
                        error={this.state.error_fields.includes('passwordconfirm')}
                        onKeyPress={this.handleKeyPress}
                        style={{ marginTop: 0.5 , width: 100 + "%", marginLeft: 'auto', marginRight: 'auto', float: 'left'}}
                    />                          

                    <TextField
                        id="passwordHint"
                        label="Password Hint"
                        onChange={this.handlePasswordHintChange}
                        value={passwordhint}
                        onKeyPress={this.handleKeyPress}
                        style={{ marginTop: 0.5 + 'vh', width: 100 + "%", marginLeft: 'auto', marginRight: 'auto', float: 'left' }}
                    />    

                    {/* <FormControlLabel style={{ marginTop: 1 + 'vh', marginLeft: "-2.3%" }} control={<Checkbox value={rememberlogin} onChange={this.handleRemChange} color="primary" />} label="Remember my login" /> */}

                    <Button onClick={this.signUp.bind(this)} variant="contained" style={{ backgroundColor: "#00a9e7", color: "white", margin: 'auto', width: 100 + "%", fontSize: 20, fontFamily: "Lato", boxShadow: 'none', marginTop: 1.5 + 'vh' }}>
                        Sign Up
                    </Button>
                </form>
                {/* <div style={{ textAlign: "center", marginTop: 3 + "%", fontSize: 13, marginBottom: '-10px' }}><a href="/recoverPassword" style={{ textDecoration: "none" }}>Forgot your password?</a></div> */}
                <div style={{ textAlign: "center", marginTop: 3 + "%", fontSize: 13 }}>
                    <div>Already have an account? <Link to="/login" style={{ color: "#00a9e7", textDecoration: "underline" }}>Click here.</Link> </div>
                    <div>Having Trouble? Contact support@virtuallogistics.ca</div>
                </div>
            </div>
        )

        var popup = (
            <React.Fragment>
                <Card style={{ position: "absolute", width: 100 + "%", height:formheight , top: 0 + "%", backgroundColor: "rgb(17, 20, 27, 0)", boxShadow:'none' }}>
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        open={this.state.open}
                        autoHideDuration={999999}
                        onClose={this.handleClose.bind(this)}
                        ContentProps={{
                            'aria-describedby': 'message-id',
                        }}
                        message={<span id="message-id">The Site uses cookies. By using this website, you agree to our use of cookies.</span>}
                        action={[
                            <ButtonBase key='close' onClick={this.handleClose.bind(this)} style={{ color: "black", backgroundColor: "#ffd42d", padding: 5, borderRadius: 5 }}>
                                I Understand
                            </ButtonBase>,
                        ]}
                    />
                    <Snackbar style={{ marginTop: "18%" }}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        open={this.state.openA}
                        onClose={this.handleCloseA}
                        ContentProps={{
                            'aria-describedby': 'message-id',
                        }}
                        message={<span id="message-id">Please make sure your username is entered. An email has been sent to your account with a verification code.</span>}
                        action={[
                            <IconButton
                                key="close"
                                aria-label="Close"
                                color="inherit"
                                onClick={this.handleCloseA}
                            >
                                <CloseIcon />
                            </IconButton>,
                        ]}
                    />
                    {/* <img style={{ height: 100 + "%", width: 100 + "%", backgroundColor: "#00a9e7" }} src={background}></img> */}
                </Card>                
            </React.Fragment>
        )

        var backdrop = (
            <React.Fragment>
                    <Backdrop style={{zIndex:"50", backgroundColor:"rgba(0,0,0,0.5)"}} open={this.state.backdrop}>
                    </Backdrop>
                    <div style={{ position:"absolute", zIndex:100, top:"50%", left:"50%", transform:"translate(-50%, -50%)"}} >
                        <CircularProgress position={{color:'white'}} size="9em" />
                    </div>
            </React.Fragment>
        )


        if (isMobile) {
            return (
                <div style={{ fontFamily: "Lato", width: '100%', height: '100vh' }}>
                    {this.state.backdrop?backdrop:null}
                    <Paper style={{ backgroundColor: "rgb(27, 37, 57, 0)", borderRadius: 0, position: "fixed", width: 100 + "%", height: 100 + "%", overflow:"auto" }}>
                        {popup}
                        <Card style={{ position: "absolute", marginLeft: 5 + "%", marginRight: "auto", width: 85 + "%", top: 3 + "%", backgroundColor: "white", verticalAlign: "middle", paddingTop: 5 + "vh", paddingRight: 3 + "%", paddingLeft: 3 + "%", textAlign: "left", borderRadius: 20, paddingBottom: "5%" }}>
                            {form}
                        </Card>
                        {dialogs}
                        
                    </Paper>
                    <LoginFooter/>
                    <img style={{ height: 100 + "%", width: 100 + "%", backgroundColor: "#00a9e7" }} src={background} alt="Background"></img>
                </div>
            );
        } else {
            return (
                <div style={{ fontFamily: "Lato" }}>
                    {this.state.backdrop?backdrop:null}
                    <Paper style={{ backgroundColor: "rgb(27, 37, 57)", borderRadius: 0, position: "fixed", width: 100 + "%", height: 100 + "%" }}>
                        {popup}
                        <OMNIInfo/>
                        <Card style={{ position: "absolute", marginLeft: 45 + "%", marginRight: "auto", width: 32 + "%", height: 77 + "%", top: 8 + "%", backgroundColor: "white", verticalAlign: "middle", paddingTop: 1 + "vh", paddingRight: 3 + "%", paddingLeft: 3 + "%", textAlign: "left", borderRadius: 20 }}>
                            {form}
                        </Card>
                        {dialogs}
                        <LoginFooter/>
                        <img style={{ height: 100 + "%", width: 100 + "%", backgroundColor: "#00a9e7" }} src={background} alt="Background 2"></img>
                    </Paper>
                </div>
            );
        }
    }

}

NewUser.propTypes = {
    classes: PropTypes.object.isRequired,
};



export default withStyles(styles)(NewUser);