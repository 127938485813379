//Created on July 9th, 2021
//Author: Vaibhavi More
import React from 'react';
import queryString from 'query-string'
import { isMobile } from "react-device-detect";
import { withStyles } from '@material-ui/core/styles';
import { Typography, Paper } from '@material-ui/core';
import { Link } from 'react-router-dom';
import * as config from '../Config/config';
import background from '../../src/loginBackgroundBlueBase.png';
import { secureStorage_getItem, secureStorage_setItem } from '../utility/secureStorage';
import apigClientFactory from '../chalice-javascript-sdk/apigClient';
import spcredsGQ from '../../src/graphql/amazonspcreds';

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
});

class Step2 extends React.Component {

    componentDidMount() {

        try {
            var customerid = secureStorage_getItem("customername")
            var values = queryString.parse(this.props.location.search)
            var accesstoken = localStorage.getItem("accesstoken");
            //gets spapi_oauth_code, state , selling partner id

            //amazon_callback_uri, amazon_state, selling_partner_id
            if (values.amazon_callback_uri) secureStorage_setItem("amazon_callback_uri", values.amazon_callback_uri)
            if (values.amazon_state) secureStorage_setItem("amazon_state", values.amazon_state)
            if (values.selling_partner_id) secureStorage_setItem("selling_partner_id", values.selling_partner_id)

            if (values.amazon_callback_uri && values.amazon_state && values.selling_partner_id) {
                //console.log("Next Step: Your website's sign-in page appears.")
                var tempQueryString = '?redirect_uri=https://myvlomni.com/vlomni-amazon-api-authorize&amazon_state=' + secureStorage_getItem("amazon_state") + '&state=' + accesstoken
                window.location = secureStorage_getItem("amazon_callback_uri") + tempQueryString;
            }

            //validate state value with access token to verify customer
            //Save the values:selling_partner_id, mws_auth_token, and spapi_oauth_code
            if (values.mws_auth_token) secureStorage_setItem("mws_auth_token", values.mws_auth_token)
            if (values.spapi_oauth_code) secureStorage_setItem("spapi_oauth_code", values.spapi_oauth_code)

            if (values.spapi_oauth_code) {
                var auth_code = secureStorage_getItem('spapi_oauth_code')
                var apigClient = apigClientFactory.newClient();
                var body = {
                    customerid: customerid,
                    info: "sp api oauth code: " + auth_code
                }

                var additionalParams = {
                    headers: {
                        "Authorization": accesstoken
                    }
                }
                apigClient.awsopsGenerateLogPut('', body, additionalParams).then(result => {
                }).catch(function (error) {
                })

                //exchange an LWA authorization code for an LWA refresh token
                var params = {
                    grant_type: "authorization_code",
                    code: auth_code,
                    redirect_uri: 'https://myvlomni.com/vlomni-amazon-api-authorize',
                    client_id: 'amzn1.application-oa2-client.e73f8974b5f449269aa9cdff1fabb8e5',
                    client_secret: 'amzn1.oa2-cs.v1.bbef171fb0ce769f462bd8796dde7fc66a030340d7dd07a973a0a63099dd9734'
                }
                // Simple POST request with a JSON body using fetch
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(params)
                };

                fetch('https://api.amazon.com/auth/o2/token', requestOptions)
                    .then(async response => {
                        const data = await response.json();
                        console.log("response from /auth/o2/token :", data);
                        //insert into rds table - temporary code, can be removed later
                        var region = localStorage.getItem("region") ? localStorage.getItem("region") : 'US'
                        var marketPlaceID = config.marketPlaceID[region]

                        var apigClient = apigClientFactory.newClient()
                        var accesstoken = localStorage.getItem("accesstoken")
                        var body = {
                            "CustomerID": secureStorage_getItem('customername'),
                            "SellerID": secureStorage_getItem('selling_partner_id'),
                            "MarketPlaceID": marketPlaceID,
                            "AccessToken": data['access_token'],
                            "RefreshToken": data['refresh_token']
                        }

                        var additionalParams = {
                            headers: {
                                "Authorization": accesstoken
                            }
                        }

                        apigClient.dbopsInsertAmazonMWS_CredsPost("", body, additionalParams).then(async (result) => {
                            let values = {
                                customerid: secureStorage_getItem('customername'),
                                info: "sp api credentials received"
                            }

                            apigClient.awsopsGenerateLogPut('', values, additionalParams).then(result => {
                            }).catch(function (error) {
                            })
                        }).catch(function (error) {
                            let values = {
                                customerid: secureStorage_getItem('customername'),
                                info: "error while inserting credentials to rds table " + error
                            }

                            apigClient.awsopsGenerateLogPut('', values, additionalParams).then(result => {
                            }).catch(function (error) {
                            })
                        })

                        var values = {
                            customerid: secureStorage_getItem('customername'),
                            info: "sp api credentials " + JSON.stringify(body)
                        }

                        apigClient.awsopsGenerateLogPut('', values, additionalParams).then(result => {
                        }).catch(function (error) {
                        });

                        //insert into dynamo table - prod us for now
                        var filters = `{ CustomerID: {eq: ${secureStorage_getItem("CustomerID")}}, MarketPlaceId: {eq: "${marketPlaceID}"}, SellerID: {eq: "${secureStorage_getItem('selling_partner_id')}"}}`

                        await spcredsGQ.listAmazonSPCreds(filters, "CustomerID").then(result => {
                            let params = {
                                "CustomerID": secureStorage_getItem('CustomerID'),
                                "SellerID": secureStorage_getItem('selling_partner_id'),
                                "MarketPlaceID": marketPlaceID,
                                "AccessToken": data['access_token'],
                                "RefreshToken": data['refresh_token']
                            }
                            if (!result.length) {
                                spcredsGQ.createAmazonSPCreds(params).then(result => {
                                    var values = {
                                        customerid: secureStorage_getItem('customername'),
                                        info: "insert sp api credentials" + JSON.stringify(result)
                                    }
                                    apigClient.awsopsGenerateLogPut('', values, additionalParams).then(result => {
                                    })
                                }).catch(function (error) {
                                    var values = {
                                        customerid: secureStorage_getItem('customername'),
                                        info: "error while inserting customer amazon credentials" + JSON.stringify(error)
                                    }
                                    apigClient.awsopsGenerateLogPut('', values, additionalParams).then(result => {
                                    })
                                })
                            } else {
                                spcredsGQ.updateAmazonSPCreds(params).then(result => {
                                    var values = {
                                        customerid: secureStorage_getItem('customername'),
                                        info: "update sp api credentials " + JSON.stringify(result)
                                    }
                                    apigClient.awsopsGenerateLogPut('', values, additionalParams).then(result => {
                                    })
                                }).catch(function (error) {
                                    var values = {
                                        customerid: secureStorage_getItem('customername'),
                                        info: "error while updating customer amazon credentials" + JSON.stringify(error)
                                    }
                                    apigClient.awsopsGenerateLogPut('', values, additionalParams).then(result => {
                                    })
                                })
                            }
                            return result
                        }).catch(error => {
                            var values = {
                                customerid: secureStorage_getItem('customername'),
                                info: "error while getting customer amazon credentials" + JSON.stringify(error)
                            }
                            apigClient.awsopsGenerateLogPut('', values, additionalParams).then(result => {
                            })
                        })

                    })
                    .catch(error => {
                        var body = {
                            customerid: secureStorage_getItem("customername"),
                            info: "error while fetching token: " + error
                        }

                        apigClient.awsopsGenerateLogPut('', body, additionalParams).then(result => {
                        }).catch(function (error) {
                        })
                    });
            } else {
                //console.log("auth code not generated")
            }

        }
        catch (error) {
            //console.log('Error ',error)
        }

    }

    render() {
        document.title = "VL OMNI";

        if (isMobile) {
            return (
                <div style={{ fontFamily: "Lato" }}>
                    <Paper style={{ backgroundColor: "rgb(27, 37, 57)", borderRadius: 0, position: "fixed", width: 100 + "%", height: 100 + "%" }}>
                        <div style={{ position: "absolute", top: 40 + "%", left: 50 + '%', verticalAlign: "middle", transform: "translate(-50%, -50%)", textAlign: "center" }}>
                            <img src={config.defaultVLLogo} style={{ maxWidth: '50px', maxHeight: '100%', marginRight: "auto" }} alt="VL OMNI" />
                            <Typography variant="h4" style={{ color: "#1db3e9", marginTop: 30 + '%', fontFamily: "Lato", fontSize: 45 + "px", fontWeight: "bold", verticalAlign: "middle" }}>THANK YOU</Typography>
                            <Typography style={{ color: "#6d6e70", margin: 30 + 'px', fontWeight: "bold", fontSize: 25 + "px", marginRight: "auto" }}>VL OMNI has received your information.</Typography>
                            <Link to="/" style={{ backgroundColor: "#ed1944", color: "white", fontWeight: "bold", fontFamily: "Lato", padding: "1em 1.5em", textDecoration: "none" }}>Take me back to Dashboard</Link>
                        </div>
                        <img style={{ width: 100 + "%", height: 100 + "%", backgroundColor: "#00a9e7" }} src={background} alt="Background"></img>
                    </Paper>
                </div>
            )
        } else {
            return (
                <div style={{ fontFamily: "Lato" }}>
                    <Paper style={{ backgroundColor: "rgb(27, 37, 57)", borderRadius: 0, position: "fixed", width: 100 + "%", height: 100 + "%" }}>
                        <div style={{ position: "absolute", top: 40 + "%", left: 50 + '%', verticalAlign: "middle", transform: "translate(-50%, -50%)", textAlign: "center" }}>
                            <img src={config.defaultVLLogo} style={{ maxWidth: '63px', maxHeight: '100%', marginRight: "auto" }} alt="VL OMNI" />
                            <Typography variant="h4" style={{ color: "#1db3e9", marginTop: 30 + '%', fontFamily: "Lato", fontSize: 45 + "px", fontWeight: "bold", verticalAlign: "middle" }}>THANK YOU</Typography>
                            <Typography style={{ color: "#6d6e70", margin: 30 + 'px', fontWeight: "bold", fontSize: 25 + "px", marginRight: "auto" }}>VL OMNI has received your information.</Typography>
                            <Link to="/" style={{ backgroundColor: "#ed1944", color: "white", fontWeight: "bold", padding: "1em 1.5em", textDecoration: "none" }}>Take me back to Dashboard</Link>
                        </div>
                        <img style={{ width: 100 + "%", backgroundColor: "#00a9e7" }} src={background} alt="Background"></img>
                    </Paper>
                </div>
            )
        }
    }
}


export default withStyles(styles)(Step2);