const initialState = {
    workflows : {}
}

const reducer = (state=initialState, action) => {
    switch(action.type){
        case "UPDATE_WORKFLOWS":
            return {
                workflows:{
                    ...state.workflows,
                    [action.workflow]:action.data
                }
            }
        default:
            return state
    }
}

export default reducer;