const axios = require('axios');

//graphql endpoint for prod-us
const graphqlEndpoint= "https://ddzh7n3sd5er7l3whlni3qauja.appsync-api.us-east-1.amazonaws.com/graphql"
const appsync_apiKey = "da2-26b73c7km5hmhgo5tnz5wdz5py"


const createAmazonSPCreds = (input) => {
    
    const query=`
        mutation {
            createAmazonSPCreds(
                input:{
                    CustomerID:${input.CustomerID},
                    SellerID: "${input.SellerID}",
                    MarketPlaceId: "${input.MarketPlaceId}", 
                    AccessToken: "${input.AccessToken}", 
                    RefreshToken: "${input.RefreshToken}", 
                }) {
                CustomerID
            }
        }
    `
    const options = {
        method: 'POST',
        url: graphqlEndpoint,
        headers: {
        'x-api-key': appsync_apiKey
        },
        data: JSON.stringify({query: query})
    }
    
    return axios.request(options).then(function (response) {
        	return response.data
        })
	.catch(function (error) {
	});
   
}

const updateAmazonSPCreds = (input)=>{
    const query=`
        mutation {
            updateAmazonSPCreds(input:{CustomerID:${input.CustomerID}, SellerID: "${input.SellerID}", MarketPlaceId: "${input.MarketPlaceId}", AccessToken: "${input.AccessToken}", RefreshToken: "${input.RefreshToken}"}) {
                CustomerID
            }
        }
    `

    const options = {
        method: 'POST',
        url: graphqlEndpoint,
        headers: {
        'x-api-key': appsync_apiKey
        },
        data: JSON.stringify({query: query})
    }

   return axios.request(options).then(function (response) {
    		return response.data
        })
	.catch(function (error) {
	})

}

const listAmazonSPCreds = (filter, fields, nextToken, limit=1000) => {
   console.log(filter)

    var token = ``
    if (nextToken){
        token = `nextToken:"${nextToken}"`
    }
    const query=`
        query {
            listAmazonSPCreds(
                filter:${filter}
                ${token}
            ){
                items{
                    ${fields}
                }
              nextToken
            }
        }
    `
            
  const options = {
    method: 'POST',
    url: graphqlEndpoint,
    headers: {
      'x-api-key': appsync_apiKey
    },
    data: JSON.stringify({query: query})
  }

   return axios.request(options).then(function (response) {
			return response.data.data.listAmazonSPCreds.items
        })
	.catch(function (error) {
	});

}
       
export default {
    createAmazonSPCreds,
    updateAmazonSPCreds,
    listAmazonSPCreds
}